import React from 'react';
import { Route, BrowserRouter, Switch, Link } from 'react-router-dom';
import { useHistory, useLocation} from 'react-router'
import asyncComponent from './asyncComponent';
import { AuthProvider, useAuth} from './Contexts'
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import MaterialLink from '@material-ui/core/Link';
import './App.css';
import {Login, Logout, AuthComp} from './routes/AuthRoutes'

const isIFrame = window !== window.parent

const EditDashboard = asyncComponent(()=> import('./routes/Dashboard'))
const ListDashboards = asyncComponent(()=> import('./routes/ListDashboards'))
const CreateDashboard = asyncComponent(()=> import('./routes/CreateDashboard'))
const ViewDashboard = asyncComponent(()=> import('./routes/ViewDashboard'))
const EditChart = asyncComponent(()=> import('./routes/EditChart'))
const ShowChart = asyncComponent(()=> import('./routes/ShowChart'))
const ListCharts = asyncComponent(()=> import('./routes/ListCharts'))
const ListQueries = asyncComponent(()=> import('./routes/ListQueries'))
const EditQuery = asyncComponent(()=> import('./routes/EditQuery'))
const CreateQuery = asyncComponent(()=> import('./routes/CreateQuery'))
const CreateChart = asyncComponent(()=> import('./routes/CreateChart'))
const SampleIFrame = asyncComponent(()=> import('./routes/SampleIFrame'))
const Test = asyncComponent(()=> import('./routes/NestedContexts'))
const Layout = asyncComponent(()=> import('./Components/Layout'))

function Breadcrumb(){
	const history = useHistory()
	const location = useLocation()
	const [ __, obj, _id ] = location.pathname.split('/')
	function to(e: React.MouseEvent<HTMLSpanElement>, path?: string){
		e.preventDefault()
		if(!path){
			return
		}
		history.push(path)
	}
	if(isIFrame){
		return null
	}
	return <Box p={1}><Breadcrumbs aria-label="breadcrumb">
		<MaterialLink color="inherit" href='/' onClick={e=>to(e, '/')}>
			BI-Dash
		</MaterialLink>
		{obj && <MaterialLink color="inherit" href={'/'+obj} onClick={e=>to(e, '/'+obj)}>
			{obj}
		</MaterialLink>}
		{_id && <Typography color="textPrimary">{_id}</Typography>}
	</Breadcrumbs>
	</Box>
}
function NavBar(){
	const {pathname} = useLocation()
	const hideNav = pathname.startsWith('/charts/') && !pathname.includes('/edit')
	if(hideNav){
		return null
	}
	return <>
		<Layout/>
		<Breadcrumb/>
	</>
}
function App(){
  	return <BrowserRouter>
	  	<AuthProvider>
			<NavBar/>
			<AuthComp/>
			<AuthRoutes/>
		</AuthProvider>
	</BrowserRouter>
}
function AuthRoutes(){
	const { isAuthed } = useAuth()
	if(!isAuthed){
		return <Switch>
			<Route path='/login' exact component={Login}/>
			<Route path='/logout' exact component={Logout}/>
			<Route path='*' component={HomeRoute}/>
		</Switch>
	}
	return <Switch>
		<Route path='/login' exact component={Login}/>
		<Route path='/logout' exact component={Logout}/>
		<Route path='/queries/new' exact component={CreateQuery}/>
		<Route path='/queries/:queryId/edit' exact component={EditQuery}/>
		<Route path='/queries/' exact component={ListQueries}/>
		<Route path='/charts/new' exact component={CreateChart}/>
		<Route path='/charts/:chartId/edit' exact component={EditChart}/>
		<Route path='/charts/:chartId' exact component={ShowChart}/>
		<Route path='/charts' exact component={ListCharts}/>
		<Route path='/sample-iframe' exact component={SampleIFrame}/>
		<Route path='/dashboards/new' exact component={CreateDashboard}/>
		<Route path='/dashboards/:dashboardId/edit' exact component={EditDashboard}/>
		<Route path='/dashboards/:dashboardId' exact component={ViewDashboard}/>
		<Route path='/dashboards' exact component={ListDashboards}/>
		<Route path='/test' exact component={Test}/>
		<Route path='*' component={HomeRoute}/>
	</Switch>
}
function HomeRoute(){
	const { isAuthed, error } = useAuth()
	if(isIFrame && error){
		return <Box p={2} className='text-center'>
			<span>Error: {error?.message}</span>
		</Box>
	}
	if(isIFrame){
		return null
	}
	return <Grid>
		<Box p={2} className='text-center'>
			<h1>
				Welcome to the BI-Dashboarding solution
			</h1>
			{error && <span>Error Logging in: {error?.message}</span>}
		</Box>
		<Box p={2}>
			<ul>
				<Link to='/charts'><li>Charts</li></Link>
				<Link to='/queries'><li>Queries</li></Link>
				<Link to='/dashboards'><li>Dashboards</li></Link>
				<ChangeAuth isAuthed={isAuthed}/>
			</ul>
		</Box>
	</Grid>
}
function ChangeAuth(props: {isAuthed:boolean}){
	if(props.isAuthed){
		return <Link to='/logout'><li>Logout</li></Link>
	}
	return <Link to='/login'><li>Login</li></Link>
}
export default App




