import React, { 
    PropsWithChildren, useEffect,
    useState, useContext, useMemo, useCallback
} from 'react'
import api, {API} from './api'
import { Authentication, Chart, Dashboard, QueryResult } from './client/typescript/src'
import { useFilterContext } from './Components/Filters'


export interface IAuthContext {
    setAuth:(auth:Authentication | undefined, error:Error | undefined)=>void,
    isAuthed:boolean;
    error?:Error
}
export const AuthContext = React.createContext<IAuthContext>({
    setAuth:(auth, error)=>undefined,
    isAuthed:false,
})
export function AuthProvider(props:PropsWithChildren<{}>){
    const [auth, setAuth] = useState<undefined | Authentication>(undefined)
	const [authError, setError] = useState<undefined | Error>(undefined)
	const authContext = React.useMemo(()=>{
		return {
			setAuth(auth:Authentication | undefined, error: Error | undefined){
				if(error){
					setError(error)
					return 
				}
				// if(!auth){
				// 	return
				// }
				api.setAuthToken(auth?.token)
				setError(undefined)
				setAuth(auth)
			},
			isAuthed:!!auth,
			error:authError
		}
	},[!!auth, authError])
    return <AuthContext.Provider value={authContext}>
        {props.children}
    </AuthContext.Provider>
}
export function useAuth(){
    return useContext(AuthContext)
}
export interface IQueryDataContext {
    chartId?:string;
    queryId?:string;
    runQuery:(queryId:string)=>void;
    fields?:QueryResult['fields'];
    rows?:QueryResult['rows'];
    resultId?:QueryResult['resultId'];
    error?:Error;
    loading?:boolean
}
export const QueryDataContext = React.createContext<IQueryDataContext>({
    runQuery:(queryId)=>undefined,
    loading:false
})
export function useQueryData(){
    return useContext(QueryDataContext)
}
export function QueryDataProvider(props: PropsWithChildren<{queryId?:string}>){
    const { queryId } = props;
    const [ res, setRes] = useState<QueryResult | undefined>()
    const filterContext = useFilterContext()
    //console.log(filterContext)
    const [ {error, isLoading}, setAsyncStatus ] = useState<AsyncStatus>({})
    const runQuery = useCallback(async function(){
        if(isLoading || !queryId){
            return
        }
        setAsyncStatus({ isLoading:true })
        try{
            setRes(await api.runQuery({
                queryId,
                filters:filterContext.filters
            }))
            setAsyncStatus({})
        }catch(error){
            setAsyncStatus({
                error:unifyErrors(error)
            })
        }
    },[isLoading, queryId])
    //@ts-ignore
	useEffect(async function(){
        if(isLoading){
            return
        }
        runQuery()
    },[ queryId ])
    const value = useMemo(()=>{
        return {
            runQuery:(queryId:string)=>{
                runQuery()
            },
            ...(res || {}),
            loading:!queryId || isLoading,
            error
        }
    },[ queryId, res?.resultId, isLoading, error ])
    return <QueryDataContext.Provider value={value}>
        {props.children}
    </QueryDataContext.Provider>
}
export interface IChartContext {
    isLoading?:boolean,
    error?:Error,
    chart?:Chart,
    chartId?:string
}
export const ChartContext = React.createContext<IChartContext>({})

interface AsyncStatus {
    isLoading?:boolean,
    error?:Error,
}
function unifyErrors(error: Error | Error[]): Error{
    if(error instanceof Array){
        return unifyErrors(error[0])
    }
    return error
}
export function ChartProvider(props: PropsWithChildren<{chartId:string}>){
    const { chartId } = props;
    const [ {error, isLoading}, setAsyncStatus ] = useState<AsyncStatus>({})
    const [ chart, setChartData ] = useState<Chart | undefined>()
    //@ts-ignore
	useEffect(async function(){
        if(isLoading){
            return
        }
        setAsyncStatus({ isLoading:true })
        try{
            const chart = await api.getChart({chartId})
            setChartData(chart)
            setAsyncStatus({})
        }catch(error: any){
            setAsyncStatus({
                error:unifyErrors(error)
            })
        }
    },[chartId])
    const value = useMemo(()=>{
        return {
            chartId,
            error, 
            isLoading,
            chart
        }
    },[chart, chartId, isLoading, error])
    return <ChartContext.Provider value={value}>
        {props.children}
    </ChartContext.Provider>
}
export function useChartContext(){
    return useContext(ChartContext)
}

export interface IDashboardContext {
    isLoading?: boolean,
    error?: Error,
    dashboard?: Dashboard,
    dashboardId?: string
}
export const DashContext = React.createContext<IDashboardContext>({})

export function useDashContext(){
    return useContext(DashContext)
}
type APIContext<T, P  extends {}> = {
    data?: T,
    isLoading?: boolean,
    error?: Error,
} & P
function useAPILoad<T, P extends {}>(props: P, getFn: (P)=>Promise<T | undefined>): APIContext<T, P> {
    const [ {error, isLoading}, setAsyncStatus ] = useState<AsyncStatus>({})
    const [ data, setData ] = useState<T | undefined>()
        //@ts-ignore
	useEffect(async function(){
        if(isLoading){
            return
        }
        setAsyncStatus({ isLoading:true })
        try{
            const data = await getFn(props)
            if(data === undefined){
                setAsyncStatus({})
                return 
            }
            setData(data)
            setAsyncStatus({})
        }catch(error: any){
            setAsyncStatus({
                error:unifyErrors(error)
            })
        }
    },Object.values(props))
    const value = useMemo(()=>{
        return {
            ...props,
            data,
            error, 
            isLoading,
        }
    },Object.values(props).concat([isLoading, error]))
    return value
}
interface GetDashboard {
    dashboardId:string
}
export function DashboardProvider(props: PropsWithChildren<GetDashboard>){
    const { data, ...restData } = useAPILoad<Dashboard, GetDashboard>({ 
        dashboardId:props.dashboardId 
    }, async (props:GetDashboard)=>{
        if(!props.dashboardId){
            return undefined
        }
        const res = await api.getDashboard(props)
        return res
    })
    const value = useMemo(function(){
        return {
            ...restData,
            dashboard:data
        }
    },[ data ].concat(Object.values(restData)))
    return <DashContext.Provider value={value}>
        {props.children}
    </DashContext.Provider>
}