import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import {RouteComponentProps, useLocation} from 'react-router'
import {AuthContext, AuthProvider, useAuth} from '../Contexts'
import { Authentication } from '../client/typescript/src';
import api from '../api';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Input } from '../Components/Forms';
import { JSONRPCMessage } from '@open-rpc/client-js/build/ClientInterface';
const isIFrame = window !== window.parent
interface PasswordAuth {
    authType:'login',
    email:string,
    password:string
}
interface JWTAuth {
    authType:'jwt',
    token:string
}
interface SessionAuth {
    authType:'session',
    token:string
}
interface NoAuth {
	authType:'none'
}
type AuthParams = PasswordAuth | SessionAuth | JWTAuth | NoAuth
async function completeAuth(params: AuthParams): Promise<Authentication>{
	if(params.authType === 'login'){
		return api.login({
			email:params.email,
			password:params.password,
			authType:'jwt'
		})
	}
	if(params.authType === 'session'){
		return api.login({
			session:params.token,
			authType:'jwt'
		})
	}
	if(params.authType === 'jwt'){
		return {
			authType:'jwt',
			token:params.token
		}
	}
	try{
		const auth = await api.confirmAuthToken()
		return auth
	}catch(error){
		throw new Error('NO_CREDENTIALS_PROVIDED')
	}
}
const acceptableOrigins = [
	"https://makerist.de",
	"https://makerist.com",
	"https://makerist.fr",
	"https://makerist.ch",
	"http://localhost:3000",
	"http://localhost:8000",
];
function isAcceptableOrigin(origin: string){
	return acceptableOrigins.includes(origin) || /^https:\/\/[a-z]+\.makerist\.[a-z]{2,3}$/.test(origin)
}
function getAuthParams(search:string): AuthParams {
	const params = new URLSearchParams(search)
	const email = params.get('email') || undefined
	const password = params.get('password') || undefined
	const session = params.get('session') || undefined
	const jwt = params.get('jwt') || undefined
	const authType = jwt ? 'jwt' : session ? 'session' : email ? 'login' : 'none'
	return {
		authType,
		password,
		email,
		token: jwt || session,
	} as AuthParams
}

function IFrameAuthComp(){
	const location = useLocation()
	const authParams = getAuthParams(location.search)
	const { setAuth, isAuthed } = useContext(AuthContext)

	const receiveMessage = useCallback(function(event: MessageEvent<any>){
		const { origin, data } = event;
		const {
			method, params
		} = data as JSONRPCMessage;
		if(!isAcceptableOrigin(origin)){
			console.warn('Message Rejected due to unaccepted origin',origin)
			setAuth(undefined, new Error('Unaccepted Domain Origin'))
			return
		}
		if(method !== 'login'){
			return
		}
		const auth = params?.[0] as Authentication
		if(!auth?.authType){
			setAuth(undefined, new Error('Invalid Auth Params'))
			return
		}
		setAuth(auth, undefined)
	},[])
	useEffect(function(){
		if(!isAuthed){
			completeAuth(authParams)
				.then(auth=>setAuth(auth, undefined))
				.catch(function(error){
					setAuth(undefined, error)
				})
			return
		}
		window.addEventListener("message",receiveMessage, false)
		return ()=>window.removeEventListener('message',receiveMessage)
	})
	return null
}
function NormalAuthComp(){
	const location = useLocation()
	const { isAuthed, setAuth, error } = useContext(AuthContext)
	const authParams = getAuthParams(location.search)
	useEffect(function(){
		if(isAuthed){
			return
		}
		completeAuth(authParams)
		.then(auth=>setAuth(auth, undefined))
		.catch(function(error){
			setAuth(undefined, error)
		})
	},[])
	return null
}
export function AuthComp(props: RouteComponentProps<{}>){
	const {pathname} = useLocation()
	if(pathname === '/login'){
		return null
	}
	if(!isIFrame){
		return <NormalAuthComp/>
	}
	return <IFrameAuthComp/>
}
type SubmitEvent =  React.FormEvent<HTMLFormElement>
export function Login(props: RouteComponentProps<{}>){
	const { setAuth, error } = useContext(AuthContext)
	const [ password, setPass] = useState('')
	const [ email, setEmail] = useState('')
	const isDisabled = !password || !email
	const login = async (e?:SubmitEvent)=>{
		e?.preventDefault()
		if(isDisabled){
			setAuth(undefined, new Error('Email & Pass required'))
			return false
		}
		try{
			const auth = await api.login({email, password, authType:'jwt'})
			setAuth(auth, undefined)
			props.history.push('/charts')
		}catch(error){
			setAuth(undefined, error)
		}
	}
	return <Grid>
		<Box p={4}>
			<h3>Login</h3>
			{error?.message && <span>ERROR: {error?.message}</span>}
			<form onSubmit={login}>
				<Input label='Email' value={email} onChange={e=>setEmail(e.target.value)}/>
				<Input label='Password' value={password} onChange={e=>setPass(e.target.value)}/>
				<Button variant='contained' disabled={isDisabled} onClick={e=>login()}>
					Login
				</Button>
			</form>
		</Box>
	</Grid>
}

export function Logout(){
    const [isLoading, setLoading] = useState(true)
    const { setAuth } = useContext(AuthContext)
    useEffect(function(){
        setAuth(undefined, undefined)
        setLoading(false)
    },[])
    if(isLoading){
        return <div>Logging out</div>
    }
    return <Redirect to='/login'/>
}




