import React, { PropsWithChildren, useCallback, useContext, useState } from 'react';
import { QueryFilter } from '../client/typescript/src'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid';
import { useLocation, useHistory } from 'react-router'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Input, SelectForm } from '../Components/Forms';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
export const FilterCombineTypeList = [
    'ignore','parent-override','child-override'
] as const
export type FilterCombineType  = typeof FilterCombineTypeList[number]
interface IEditFilterProps {
    filters:QueryFilter[]
    filterMeta?:{included:boolean, location:string}[],
    updateFilters?:(filters:QueryFilter[])=>void;
    context?: string
}
function collectFilterType(filter: QueryFilter): QueryFilter{
    const isArray = filter.value instanceof Array
    const shouldBeArray = ARRAY_BASED.includes(filter.type)
    if( shouldBeArray && !isArray){
        return {
            ...filter,
            value:filter.value.toString().split(',')
        } as QueryFilter
    }else if(!shouldBeArray && isArray ){
        return {
            ...filter,
            value:(filter.value as string[]).join(',')
        } as QueryFilter
    }
    if(typeof filter.value === 'string' && ['true','false'].includes(filter.value.toLowerCase())){
        filter.value = filter.value.toLowerCase() === 'true'
    }
    return filter
}
function EditFilters(props:IEditFilterProps){
    const { filters, updateFilters } = props;
    const isDisabled = !updateFilters
    const changeFilter = useCallback((oldKey: string, newFilter?: QueryFilter)=>{
        if(!updateFilters){
            return
        }
        if(newFilter === undefined){
            return updateFilters(filters.filter(v=>v.key !== oldKey))
        }
        let updated = 0
        const output = filters.map(function(filter){
            if(filter.key === oldKey){
                updated++
                return collectFilterType(newFilter)
            }
            return collectFilterType(filter)
        })
        if(updated === 0){
            output.push(newFilter)
        }
        return updateFilters(output)
    },[filters, updateFilters])
    const addFilter = useCallback(function(){
        changeFilter('newFilter',{key:'newFilter',type:'=',value:''})
    },[changeFilter])
    return <Grid container>
        <Grid item xs={12}>
            Filters {props.context}
            <Button variant='contained' size='small' color='primary' onClick={addFilter}>
                Add Filter
            </Button>
        </Grid>
        <Grid item xs={12}>
            {props.filters.map(function({key, ...filter},i){
                const filterMeta = props.filterMeta?.[i];
                return <Filter key={i} id={key} {...filter} filterMeta={filterMeta} changeFilter={changeFilter} isDisabled={isDisabled}/>
            })}
        </Grid>
    </Grid>
}
export function FiltersDialog(props: IEditFilterProps & {onClose:()=>void, open:boolean, title?:string}){
    const {open, onClose, title, ...editFilterProps} = props;
    return <Dialog open={open} onClose={onClose}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
            {open && <EditFilters {...editFilterProps}/>}
        </DialogContent>
    </Dialog>
}
export function ProvidedFiltersDialog(props:{title?:string}){
    const { filters, setFilters } = useFilterContext()
    const [ editFilters, setEditFilters] = useState(false)
    return <><FiltersDialog 
        title={props.title}
        filters={filters}
        open={editFilters} 
        updateFilters={filters=>setFilters(filters)} 
        onClose={()=>setEditFilters(false)}
    />
    <IconButton onClick={e=>setEditFilters(true)}>
        <FilterListIcon/>
    </IconButton>
    </>
}

let RENDER_ID = 0
export interface IFilterContext {
    renderId: number;
    filters: QueryFilter[],
    allFilters: QueryFilter[],
    filterMeta: {
        location:string,
        included:boolean
    }[],
    parents: string[],
    location: string,
    setFilters:(filters:QueryFilter[])=>void
}
export const FilterContext = React.createContext<IFilterContext>({
    renderId:RENDER_ID++,
    filters:[],
    allFilters:[],
    filterMeta:[],
    parents:[],
    location:'root',
    setFilters:(filters:QueryFilter[])=>{}
})
export function useFilterContext(){
    return useContext(FilterContext)
}
function mergeFilters(
    combineType:FilterCombineType = 'parent-override',
    parent:IFilterContext,
    child:QueryFilter[],
    location:string
): Omit<IFilterContext,'setFilters' | 'renderId'>{
    const { allFilters, filterMeta, parents} = parent
    const childMeta = child.map(v=>{
        return {
            location,
            included:true
        }
    })
    if(combineType === 'ignore'){
        return {
            location,
            parents:parents.concat(parent.location),
            filters:child,
            allFilters:child.concat(allFilters),
            filterMeta:childMeta.concat(filterMeta.map(function(filter){
                return { ...filter, included:false }
            }))
        }
    }
    let [a, b] : QueryFilter[][] = combineType === 'parent-override' ? [ allFilters , child ] : [ child, allFilters ]
    let [x, y] : IFilterContext['filterMeta'][] = combineType === 'parent-override' ? [ filterMeta , childMeta] : [ childMeta, filterMeta ]
    const newFilters = a.concat()
    const newMeta = x.concat()
    b.forEach(function(filter, i){
        const exists = !!newFilters.find(v=>v.key === filter.key)
        newFilters.push(filter)
        let meta = {...y[i]}
        if(exists){
            newMeta.push({...meta, included:false})
        }else{
            newMeta.push(meta)
        }
    })
    return {
        location,
        parents:parents.concat(parent.location),
        filters:child,
        allFilters:newFilters,
        filterMeta:newMeta
    }
}

export function FilterProvider(props:PropsWithChildren<{location:string, combineType?:FilterCombineType}>){
    const {location, combineType} = props;
    const filterContext = useFilterContext()
    const state = useState<QueryFilter[]>([]) 
    const queryParams = useFilters()
    const [ filters, setFilters ] = filterContext.location === 'root' ? queryParams : state
	const newContext = React.useMemo(()=>{
        return {
            renderId: RENDER_ID ++,
            ...mergeFilters(combineType, filterContext, filters, location),
            setFilters
        }
	},[ filterContext, filters, combineType, setFilters])
    return <FilterContext.Provider value={newContext}>
        {props.children}
    </FilterContext.Provider>
}
type SetFilters = (filters: QueryFilter[])=>void;
function useFilters(): [QueryFilter[], SetFilters]{
    const location = useLocation()
    const history = useHistory()
    const params = new URLSearchParams(location.search)
    const setFilters: SetFilters = (filters)=>history.push(`?filters=${JSON.stringify(filters)}`)
    try {
        const filters = params.get('filters')
        if(!filters){
            return [[], setFilters]
        }
        return [ JSON.parse(filters), setFilters ]
    } catch (error) {
        console.log(error)
        return [ [], setFilters]
    }
}
const FilterTypeList = [
    "like","not like",
    "in","not in",
    "between","not between",
    "=","!=",
    "<","<=",">",">="
]
const OPTIONS = FilterTypeList.map(function(value){
    return {el:value.toUpperCase(), value}
})
const ARRAY_BASED = [
    "in","not in",
    "between","not between"
]
type FilterProps = Omit<QueryFilter,'key'> & {
    filterMeta?:{included:boolean, location:string},
    isDisabled?:boolean,
    id:string,
    changeFilter:(oldKey: string, newFilter?: QueryFilter)=>void
}
function Filter(props: FilterProps){
    const { id, filterMeta, isDisabled=false, changeFilter, ...restProps } = props
    const updateFilter = useCallback((key: string, value: string)=>{
        const newData = { [key]:value }
        const newFilter: QueryFilter = {
            ...restProps,
            ...newData,
            key:newData.key || id
        }
        changeFilter(id, newFilter)
    },[props])
    const removeFilter = useCallback(()=>{
        changeFilter(id, undefined)
    },[id, changeFilter])
    return <Box p={2}>
        {filterMeta && <span>{filterMeta.location} {filterMeta.included ? '+' : 'x'}</span>}
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <Input label='Key' value={props.id} 
                    disabled={isDisabled}
                    onChange={e=>updateFilter('key',e.target.value)}/>
            </Grid>
            <Grid item xs={2}>
                <SelectForm 
                    label='Type' value={props.type} options={OPTIONS} 
                    disabled={isDisabled}
                    onChange={e=>updateFilter('type',e.target.value as string)}/>
            </Grid>
            <Grid item xs={5}>
            <Input disabled={isDisabled} label='Value' value={(props.value??'').toString()} 
                onChange={e=>updateFilter('value',e.target.value)}/>
            </Grid>
            <Grid item xs={1}>
                {!isDisabled && <IconButton onClick={removeFilter}>
                    <DeleteIcon/>
                </IconButton>}
            </Grid>
        </Grid>
    </Box>
}