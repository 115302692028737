import MakeristDashboards, { QueryResult, Authentication } from './client/typescript/src'
import { JSONRPCError } from "@open-rpc/client-js"
import { BaseType, DataSet } from './ChartObjects/types'
export { JSONRPCError } 
const TOKEN_KEY = '_bidash-jwt'
export class API extends MakeristDashboards{
    token?:string
    constructor(options: ConstructorParameters<typeof MakeristDashboards>[0]){
        super(options)
        const token = window.localStorage.getItem(TOKEN_KEY)
        if(token){
            this.setAuthToken(token)
        }
    }
    getAuthToken(): string | undefined{
        return this.token
    }
    setAuthToken(token: string | undefined): this{
        if(token === undefined){
            //@ts-ignore
            this.transport.headers.delete('Authorization')
            window.localStorage.removeItem(TOKEN_KEY)
            this.token = token
            return this
        }
        window.localStorage.setItem(TOKEN_KEY,token)
        this.token = token
        //@ts-ignore
        this.transport.headers.set('Authorization','Bearer '+token)
        return this
    }
    async confirmAuthToken(this: API): Promise<Authentication>{
        if(!this.token){
            throw new Error('PERMISSION DENIED')
        }
        await this.getMe()
        return {
            authType:'jwt',
            token:this.token
        }
    }
    static mapBigInt(fields: QueryResult['fields'], rows:DataSet): DataSet{
        const mapFns = fields.map(function({name,type}){
            if(type !== 'BIGINT' && type !== 'BIGFLOAT'){
                return 
            }
            return function(row: Record<string, BaseType>): Record<string, BaseType>{
                const value = row[name] as string | undefined | null
                if(value === null || value === undefined){
                    return row
                }
                return { 
                    ...row, 
                    [name]:type === 'BIGINT' ? parseInt(value) : parseFloat(value)
                }
            }
        }).filter(v=>v !== undefined)
        if(mapFns.length === 0){
            return rows
        }
        return rows.map(function(row){
            for(let fn of mapFns){
                if(!fn){
                    continue
                }
                row = fn(row)
            }  
            return row
        })
    }
}
const api = new API({
	transport:createTransportOptions()
})
function createTransportOptions(){
    const { hostname,protocol,port} = window.location
    const type = protocol.replace(':','') as 'http' | 'https'
    return {
		type,
		host:hostname,
		port:port ? parseInt(port) : type === 'http' ? 80 : 443,
		path:'api/v0.1',
	}
}
//@ts-ignore
const requestFn = api.request.bind(api)
//@ts-ignore
api.request = async function request(methodName: string, params: any[]): Promise<any> {
    try{
        const res = await requestFn(methodName, params)
        return res
    }catch(error){
        if(error instanceof JSONRPCError && error.message.startsWith('PERMISSION DENIED: login required')){
            this.setAuthToken(undefined)
        }
        console.log(error)
        throw error
    }
}
export default api





