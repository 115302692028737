import React, { ChangeEvent,  ReactNode } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';


export interface InputP {
    value?:string,
    label?:string;
    name?:string;
    disabled?:boolean;
    onChange:(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => any;
}
export const Input =  React.memo(function Input(props:InputP){
    const {value,label,onChange,name, disabled} = props
    return <FormControl fullWidth >
        <TextField
            disabled={disabled}
            name={name}
            value={value}
            onChange={onChange}
            label={label}
        />
    </FormControl>
})
export interface SwitchP {
    name?: string;
    disabled?:boolean;
    value: boolean;
    label: string;
    onChange:(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => any;
}
export function SwitchInput(props: SwitchP){
    const {value,label,onChange,name, disabled} = props;
    return <FormControl component="fieldset">
        <FormGroup>
            <FormControlLabel 
                disabled={disabled}
                control={<Switch checked={value} color="primary" onChange={onChange} name={name} />}
                label={label}
            />
        </FormGroup>
    </FormControl>
}

type SelectChangeFn = (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: ReactNode) => void
export interface SelectFormP{
    onChange:SelectChangeFn,
    value?:string,
    label?: string,
    name?: string;
    disabled?: boolean;
    options:{
        value:string,
        el:React.ReactNode | string
    }[]
}
export const SelectForm =  React.memo(function SelectForm(props: SelectFormP){
    const { onChange, value, options, label, name, disabled } = props
    return <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select value={value} onChange={onChange} name={name} disabled={disabled}>
            {
                options.map(function({value,el}){
                    return <MenuItem key={value} value={value}>{el}</MenuItem>
                })
            }
        </Select>
    </FormControl>
})
