import React from 'react'
interface State {
  component: JSX.Element | null
}
export default function asyncComponent(importComponent: Function) {
  class AsyncComponent<T=Record<string,any>> extends React.Component<T,State> {
    constructor(props){
      super(props)
      this.state = {
        component: null
      }
    }
    async componentDidMount() {
      const { default: component }: {default:JSX.Element} = await importComponent();
      this.setState({ component });
    }
    render() {
      const { component: C } = this.state;
      if(!C){
        return null
      }
      //@ts-ignore
      return <C {...this.props} />;
    }
  }
  return AsyncComponent;
}